@use "../../../assets/scss/colors";

.list {
  display: none;
  box-sizing: border-box;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translateY(100%);
  flex-direction: column;
  gap: 16px;
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background-color: colors.$grey-light-grey;

  li {
    display: flex;
    gap: 8px;
    padding: 8px 0;
  }
}

.opened {
  display: flex;
}