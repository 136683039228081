@use "../../../../../assets/scss/typography";
@use "../../../../../assets/scss/colors";

.container {
  margin-bottom: 13px;
  padding: 16px 16px 16px 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  border-radius: 16px;
  background-color: #FFFFFF;

  h2 {
    flex-shrink: 1;
    color: colors.$grey-black;
    @include typography.sub-heading;
  }

  .search {
    width: 40%;
    padding: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background-color: colors.$grey-light-grey;

    input {
      width: 100%;
      background-color: transparent;
      border: none;
      outline: none;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
      text-overflow: ellipsis;

      &::placeholder {
        color: #A3A3A3;
      }

      &::-ms-clear {
        display: none;
        width: 0;
        height: 0;
      }

      &::-ms-reveal {
        display: none;
        width: 0;
        height: 0;
      }

      &::-webkit-search-decoration,
      &::-webkit-search-cancel-button,
      &::-webkit-search-results-button,
      &::-webkit-search-results-decoration {
        display: none;
      }
    }
  }
}

@media (max-width: 1000px) {
  .container {
    flex-direction: column;
    gap: 8px;
    padding-left: 16px;

    .search {
      width: 100%;
    }
  }
}