.main {
  grid-area: center;
  width: 100%;
  max-width: 100%;
  height: fit-content;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .loader {
    height: 56px;
    width: 56px;
  }
}