.newFileButton {
  border-radius: 8px;
}

.hamburger {
  padding: 0;
  display: none;
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.avatarButton {
  padding: 0;
  display: flex;
  border-radius: 50%;
}

@media (max-width: 1200px) {
  .hiddenAvatarButton {
    display: none;
  }
  .hamburger {
    display: flex;
  }
}

@media (max-width: 768px) {
  .newFileButton {
    span {
      display: none;
    }
  }
}

@media (max-width: 360px) {
  .newFileButton {
    padding-left: 16px;
    padding-right: 16px;
  }
}