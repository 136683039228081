@use '../../../../../assets/scss/typography';

.container {
  margin-bottom: 19px;
  padding: 24px;
  box-sizing: border-box;
  width: 100%;
  border-radius: 16px;
  background-color: #FFFFFF;
  cursor: pointer;

  .header {
    margin-bottom: 16px;
    position: relative;
    display: flex;
    align-items: center;
    gap: 8px;

    .text {
      flex-grow: 1;
      overflow: hidden;

      h2 {
        color: #292929;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      h3 {
        color: #A3A3A3;
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .iconBtn {
      padding: 0;

      .icon {
        display: block;
      }
    }
  }

  .productImg {
    width: 100%;
    max-width: 100%;
    aspect-ratio: 584/286;
    object-fit: contain;
    border-radius: 8px;
  }

  .name {
    color: #292929;
    @include typography.sub-heading;
  }

  .reviews {
    display: flex;
    align-items: center;
    gap: 4px;

    p {
      color: #292929;
      font-size: 12px;
      font-weight: 300;
      line-height: 24px;
    }
  }
}