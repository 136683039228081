.container {
  padding: 16px 20px 0;
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
  gap: 16px;

  .content {
    width: 848px;
    max-width: 100%;
    border-radius: 16px;
    overflow: hidden;
    background-color: #FFFFFF;

    .productImg {
      width: 100%;
      aspect-ratio: 848/414;
      object-fit: contain;
    }

    .readMoreReviewsBtn {
      padding: 16px;
      margin-left: auto;
      margin-right: auto;
      display: flex;
      align-items: center;
      gap: 4px;

      p {
        color: #666666;
        font-size: 12px;
        font-weight: 300;
        line-height: 16px;
      }
    }
  }

  .loader {
    height: 56px;
    width: 56px;
  }

  .transparentContent {
    background-color: transparent;
  }
}

@media (max-width: 1000px) {
  .container {
    flex-direction: column;
  }
}