@use "../../assets/scss/typography";
@use "../../assets/scss/colors";

.container {
  background-color: #FFFFFF;
  margin-bottom: 50px;
  box-sizing: border-box;
  padding: 16px;
  width: 366px;
  max-width: 100%;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 24px;
  color: #292929;

  .info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 8px;

    .icon {
      width: 56px;
      height: 56px;
    }

    h2 {
      @include typography.heading-small;
      text-align: center;
    }

    p {
      text-align: center;
      @include typography.body-large;
    }
  }

  .buttons {
    display: flex;
    gap: 8px;

    .cancelButton, .confirmButton {
      padding: 16px;
      width: 50%;
    }

    .cancelButton {
      background-color: colors.$grey-light-grey;
      color: #A3A3A3;
    }

    .confirmButton {
      background-color: rgba(#E00000, 0.08);
      color: #E00000;

      .loader {
        width: 20px;
        height: 20px;
        border-top: 2px solid #E00000;
      }
    }
  }
}

@media (max-width: 360px) {
  .container {
    .buttons {
      gap: 4px;

      .cancelButton, .confirmButton {
        padding: 12px;
      }
    }
  }
}