.container {
  padding: 16px 80px 0;
  display: grid;
  grid-template-columns: 23% auto 23%;
  grid-column-gap: 16px;

  .stickySidebar {
    position: sticky;
    top: 120px;
    height: fit-content;
  }

  .loader {
    height: 56px;
    width: 56px;
  }
}

@media (max-width: 1440px) {
  .container {
    grid-template-columns: 23% auto;
  }
}

@media (max-width: 1200px) {
  .container {
    grid-template-columns: 100%;
  }
}

@media (max-width: 1000px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}