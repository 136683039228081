@use '../../../assets/scss/colors';
@use '../../../assets/scss/typography';

.ratings {
  padding: 16px;
  color: #292929;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .header {
    display: flex;
    justify-content: space-between;

    h2 {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
    }

    .giveReviewButton {
      padding: 8px 16px;
      border-radius: 8px;
    }
  }

  .inner {
    display: flex;
    gap: 64px;
    padding: 32px;
    border-radius: 16px;
    border: 1px solid #F5F5F5;

    .leftSide {
      display: flex;
      flex-direction: column;
      align-items: center;

      .rating {
        font-size: 56px;
        font-weight: 400;
        line-height: 56px;
      }

      .reviewsCount {
        align-self: flex-start;
        @include typography.body-large;
      }
    }

    .rightSide {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .ratingWrapper {
        display: flex;
        gap: 8px;

        p {
          min-width: 11px;
          text-align: center;
        }

        .lineWrapper {
          height: 100%;
          flex-grow: 1;
          background-color: #F5F5F5;

          .lineInner {
            height: 100%;
            width: 100%;
            background-color: #FFBF00;
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .ratings {
    .inner {
      gap: 32px;
    }
  }
}

@media (max-width: 425px) {
  .ratings {
    .inner {
      padding: 16px;
    }

    .leftSide {
      .starIcon {
        width: 20px;
        height: 20px;
      }
    }
  }
}

@media (max-width: 360px) {
  .ratings {
    .inner {
      gap: 16px;
    }

    .leftSide {
      .starIcon {
        width: 16px;
        height: 16px;
      }
    }
  }
}