@use "../../assets/scss/colors";
@use "../../assets/scss/typography";

.container {
  padding: 16px;
  width: 732px;
  max-width: calc(100% - 2 * 16px);
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background-color: #FFFFFF;
  cursor: auto;

  h2 {
    align-self: flex-start;
    color: #292929;
    @include typography.sub-heading;
  }

  textarea {
    resize: none;
    box-sizing: border-box;
    height: 112px;
    width: 100%;
    padding: 16px;
    border-radius: 16px;
    background-color: colors.$grey-light-grey;
    border: none;
    outline: none;
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
  }

  .notAllowedFieldValue {
    outline: 1px solid red;
  }

  .button {
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: rgba(#E00000, 0.08);
    color: #E00000;

    .loader {
      width: 20px;
      height: 20px;
      border-top: 2px solid #E00000;
    }
  }
}