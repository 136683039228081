@use "../../assets/scss/typography";

.container {
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(0, calc(100% + 10px));
  z-index: 1;
  width: 414px;
  max-width: 100%;
  border-radius: 16px;
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;

  .btn {
    position: relative;
    padding: 16px;
    display: flex;
    gap: 8px;
    border-radius: 0;

    p {
      color: #292929;
      @include typography.body-large;
    }

    &:not(:last-child):after {
      content: "";
      z-index: 1;
      position: absolute;
      bottom: 0;
      width: calc(100% - 2 * 16px);
      height: 1px;
      background-color: #E0E0E0;
    }

    .redText {
      color: #E00000;
    }
  }
}
//
//@media (max-width: 530px) {
//  .container {
//    max-width: 70vw;
//  }
//}