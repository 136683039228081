@use '../../../assets/scss/colors';

.influencer {
  margin: 16px;
  padding: 16px;
  border-radius: 16px;
  background-color: colors.$grey-light-grey;
  display: flex;
  gap: 8px;
  cursor: pointer;

  .influencerInfo {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0;
    overflow: hidden;

    h2 {
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #292929;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    h3 {
      font-size: 12px;
      font-weight: 400;
      line-height: 24px;
      color: #A3A3A3;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .button {
    margin-left: auto;
    padding: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;

    .followIcon {
      width: 16px;
      height: 16px;
    }

    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
    }
  }
}

@media (max-width: 460px) {
  .influencer {

    .button {

      p {
        display: none;
      }
    }
  }
}