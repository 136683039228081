@use '../../../../../assets/scss/typography';

.container {
  padding: 16px;
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background-color: #FFFFFF;

  img {
    width: 16%;
    min-width: 104px;
    aspect-ratio: 104/72;
    object-fit: cover;
    border-radius: 8px;
  }

  .listImageSubstitute {
    width: 16%;
    min-width: 104px;
    aspect-ratio: 104/72;
    object-fit: cover;
    border-radius: 8px;
    outline: 2px dotted grey;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .text {
    flex-grow: 1;
    overflow: hidden;

    .header {
      display: flex;
      justify-content: space-between;

      h2 {
        color: #292929;
        @include typography.body-large-bold;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .iconBtn {
        padding: 0;

        .icon {
          display: block;
        }
      }
    }

    p {
      color: #A3A3A3;
      @include typography.body-large;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .sandwichPopup {
    bottom: auto;
    top: 30px;
    transform: translateY(0);
  }
}

.containerCursorPointer {
  cursor: pointer;
}