@use '../../assets/scss/colors';
@use '../../assets/scss/typography';

.container {
  width: 100%;
  min-width: 21%;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .user {
    display: flex;
    gap: 16px;
    padding: 24px;
    border-radius: 16px;
    background-color: #FFFFFF;

    .text {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      overflow: hidden;

      h2 {
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        color: colors.$grey-black;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      h3 {
        font-size: 12px;
        font-weight: 400;
        line-height: 24px;
        color: #A3A3A3;
        overflow: hidden;
        text-overflow: ellipsis;
      }

    }

  }

  .links {
    padding: 24px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    border-radius: 16px;
    background-color: #FFFFFF;

    .link {
      padding: 8px;
      position: relative;
      display: flex;
      align-items: center;
      gap: 16px;
      color: #525252;
      @include typography.body-large;

      .active {
        color: colors.$primary;
        font-weight: 600;
      }
    }

    :not(:last-child):after {
      content: "";
      position: absolute;
      bottom: -8px;
      left: 0;
      width: 100%;
      height: 1px;
      background-color: #F5F5F5;
    }

  }

  .logout {
    display: flex;
    align-items: center;
    gap: 16px;
    color: #E00000;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    background-color: rgba(224, 0, 0, 0.08);
  }

  .icon {
    width: 32px;
    height: 32px;
  }

  .notificationIconAndCounterWrapper {
    position: relative;
    display: flex;
  }

  .notificationCounter {
    position: absolute;
    top: 0;
    left: 16px;
    background-color: red;
    color: white;
    padding: 0 4px;
    border-radius: 22px;
    min-width: 8px;
    max-width: 18px;
    height: 16px;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
  }
}

@media (max-width: 1200px) {
  .container {
    display: none;
  }
}