@use "../../assets/scss/typography";
@use "../../assets/scss/colors";

.container {
  box-sizing: border-box;
  padding: 16px;
  width: 366px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  background-color: #FFFFFF;

  h2 {
    color: #292929;
    @include typography.body-large-bold;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    gap: 8px;

    input, .dropdown, .dragAndDrop {
      box-sizing: border-box;
      width: 100%;
      padding: 16px;
      border-radius: 8px;
      background-color: colors.$grey-light-grey;
      border: none;
      outline: none;
      font-size: 12px;
      font-weight: 300;
      line-height: 24px;

      &::placeholder {
        color: #A3A3A3;
      }
    }

    .dropdown {
      z-index: 1;
      flex: 1 0;
      display: flex;
      justify-content: space-between;
      cursor: pointer;
      color: #A3A3A3;
      position: relative;

      .opened {
        display: flex;
        width: 296px;
        max-width: 100%;
        padding: 16px;
        border-radius: 16px;
        background-color: #FFFFFF;
      }
    }

    .dragAndDrop {
      position: relative;
      padding: 0;
      aspect-ratio: 600/224;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      border: 1px dashed #E0E0E0;
      cursor: pointer;

      p {
        color: #A3A3A3;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        opacity: 1;
      }

      .removeImageButton {
        padding: 0;
        display: flex;
        position: absolute;
        background-color: transparent;
        top: 10px;
        right: 10px;
      }
    }

    .dragAndDropDisabled {
      cursor: default;
    }

    .fileInput {
      display: none
    }

    .notAllowedFieldValue {
      outline: 1px solid red;
    }

    .notAllowedFileInputValue {
      border-color: red;
    }
  }

  .loader {
    width: 20px;
    height: 20px;
  }
}