@use '../../../../assets/scss/colors';
@use '../../../../assets/scss/typography';

.container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border-radius: 16px;
  background-color: #FFFFFF;
  cursor: pointer;

  .recommended {
    padding: 16px;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 8px;
    background-color: rgba(colors.$primary, 0.08);

    p {
      color: colors.$primary;
      @include typography.body-large;

      span {
        font-weight: 600;
      }
    }

  }

  .main {
    margin-bottom: 8px;
    display: flex;
    justify-content: space-between;
    gap: 8px;

    .influencerInfo {
      flex-grow: 1;
      flex-direction: column;
      gap: 0;
      overflow: hidden;

      h2 {
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        color: #292929;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      h3 {
        font-size: 12px;
        font-weight: 300;
        line-height: 24px;
        color: #A3A3A3;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    .followButton {
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      align-items: center;
      gap: 8px;
      border-radius: 8px;

      .followIcon {
        width: 16px;
        height: 16px;
      }

      p {
        font-size: 14px;
        font-weight: 600;
        line-height: 16px;
      }

      .loader {
        width: 16px;
        height: 16px;
      }
    }
  }

  .extendedInfo {
    padding: 16px;
    border-radius: 16px;
    display: flex;
    gap: 8px;
    background-color: colors.$grey-light-grey;

    div {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      @include typography.sub-heading;

      h2 {
        color: #292929;
      }

      h3 {
        color: #A3A3A3;
        font-weight: 300;
      }
    }
  }
}

@media (max-width: 460px) {
  .container {
    .main {
      .followButton {
        p {
          display: none;
        }
      }
    }

    .extendedInfo {
      flex-wrap: wrap;

      div {
        width: calc(50% - 4px);
      }
    }
  }
}