.list {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  border-radius: 16px;
  background-color: #FFFFFF;

  li {
    display: flex;
    align-items: center;
    gap: 8px;
  }
}

.opened {
  display: flex;
}