.container {
  width: 100%;
  max-width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 8px;

  p {
    margin-left: auto;
    margin-right: auto;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    color: #A3A3A3;

    &:not(:first-child) {
      margin-top: 16px;
    }
  }
}