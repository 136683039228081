@use '../../assets/scss/typography';

.container {
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 16px;
  border-radius: 16px;
  background-color: #FFFFFF;

  h2 {
    flex-grow: 1;
    color: #A3A3A3;
    @include typography.body-large;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .button {
    border-radius: 8px;
  }
}

@media (max-width: 450px) {
  .container {
    .button {
      padding-right: 16px;
      padding-left: 16px;
    }
  }
}