@use '../../../../assets/scss/typography';

.notification {
  padding: 16px;
  border-radius: 16px;
  background-color: #FFFFFF;
  display: flex;
  gap: 16px;

  .info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: #292929;

    h2 {
      @include typography.body-large-bold;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      cursor: pointer;
    }

    h3 {
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}