@use "../../assets/scss/typography";
@use "../../assets/scss/colors";

.container {
  box-sizing: border-box;
  padding: 16px;
  width: 366px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  background-color: #FFFFFF;

  h2 {
    color: #292929;
    font-size: 24px;
    font-weight: 600;
    line-height: 32px;
  }

  .dragAndDrop {
    box-sizing: border-box;
    width: 100%;
    border-radius: 8px;
    background-color: colors.$grey-light-grey;
    outline: none;
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
    position: relative;
    padding: 0;
    aspect-ratio: 600/224;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px dashed #E0E0E0;
    cursor: pointer;

    &::placeholder {
      color: #A3A3A3;
    }

    p {
      color: #A3A3A3;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .removeFileButton {
      padding: 0;
      display: flex;
      position: absolute;
      background-color: transparent;
      top: 10px;
      right: 10px;
    }

    .selectedFileName {
      word-break: break-all;
      color: #292929;
      font-size: 16px;
      font-weight: 500;
      width: fit-content;
      max-width: 100%;
    }
  }

  .dragAndDropDisabled {
    cursor: default;
  }

  .notAllowedFieldValue {
    color: red
  }

  .notAllowedFileInputValue {
    border-color: red;
  }

  .fileInput {
    display: none
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .products {
    display: flex;
    flex-wrap: nowrap;
    gap: 4px;
    white-space: nowrap;
    overflow: hidden;

    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .loader {
      margin: 0;
      height: 12px;
      width: 12px;
    }
  }

  .uploadButton {
    .loader {
      height: 20px;
      width: 20px;
    }
  }
}