.container {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .loader {
    height: 56px;
    width: 56px;
  }
}