.container {
  padding: 16px 80px 0;
  display: grid;
  grid-template-columns: 23% auto 23%;
  grid-column-gap: 16px;
  grid-template-areas: "left center right";

  .stickySidebar {
    position: sticky;
    top: 120px;
    height: fit-content;
  }
}

@media (max-width: 1440px) {
  .container {
    grid-template-columns: 23% auto;
    grid-template-areas: "left center";
  }

  .unauthorizedContainer {
    grid-template-columns: auto;
    grid-template-areas: "center";
  }

}

@media (max-width: 1200px) {
  .container {
    grid-template-columns: 100%;
    grid-template-areas: "center";
  }
}

@media (max-width: 1000px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}