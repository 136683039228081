@use '../../../../assets/scss/typography';

.container {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 8px;
  cursor: pointer;

  img {
    width: 27%;
    aspect-ratio: 120/88;
    object-fit: cover;
    border-radius: 16px;
  }

  .info {
    flex-grow: 1;
    align-self: center;

    h2 {
      @include typography.body-large-bold;
    }

    p, h3 {
      font-size: 12px;
      font-weight: 300;
    }

    p {
      line-height: 24px;
      display: flex;
      align-items: center;
    }

    h3 {
      line-height: 16px;
    }
  }

  .dotsBtn {
    padding: 0;
    align-self: flex-start;

    .icon {
      display: block;
    }
  }

  .sandwichPopup {
    bottom: auto;
    top: 0;
    right: 10px;
    max-width: calc(100% - 10px);
    transform: translateY(0);

    button {
      padding: 8px;
    }
  }
}

@media (max-width: 1000px) {
  .container {
    img {
      max-width: 120px;
    }
  }
}

@media (max-width: 550px) {
  .container {
    .sandwichPopup {
      button {
        padding: 4px;
      }
    }
  }
}