@use '../../../assets/scss/typography';

.container {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #292929;

  .header {
    position: relative;
    display: flex;
    justify-content: space-between;

    .author {
      display: flex;
      gap: 8px;
      max-width: calc(100% - 64px);
      overflow: hidden;

      .authorInfo {
        display: flex;
        flex-direction: column;
        max-width: calc(100% - 48px);

        h2 {
          @include typography.sub-heading;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          cursor: pointer;
        }

        .stars {
          display: flex;
          flex-wrap: nowrap;

          .starIcon {
            width: 16px;
            height: 16px;
          }
        }
      }
    }

    .buttons {
      display: flex;
      gap: 16px;

      .likedText {
        color: #2C56E4;
      }

      .dotsButton {
        padding: 0;
        display: flex;
        height: fit-content;

        .dotsIcon {
          transform: rotate(90deg);
        }
      }

      p {
        @include typography.tertriary;
        text-align: center;
      }

      .like {
        .btn {
          padding: 0;
        }
      }
    }

    .sandwichPopup {
      bottom: 0;
      transform: translateY(0);
    }
  }

  .text {
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
    word-break: break-word;
  }
}