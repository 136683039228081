@use "../../../../assets/scss/colors";

.container {
  position: relative;
  padding: 16px;
  overflow: hidden;
  border-radius: 16px;
  background-color: #FFFFFF;

  &:after {
    content: "";
    position: absolute;
    width: 1px;
    height: calc(100% - 16px * 2);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #E0E0E0;
  }

  .button {
    padding: 0;
    width: 50%;
    border-radius: 0;
    color: #A3A3A3;

    h2 {
      font-size: 16px;
      font-weight: 300;
      line-height: 24px;

      .active {
        color: colors.$grey-black;
        font-weight: 500;
      }
    }
  }
}