@use '../../../assets/scss/colors';

.description {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: #292929;

  h2 {
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
  }

  p, a {
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;
  }

  a {
    color: colors.$primary;
    cursor: pointer;
  }

  .button {
    padding: 0;
    align-self: center;
    display: flex;

    p {
      color: #666666;
    }
  }

  .buyButton {
    padding-left: 16px;
    padding-right: 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    width: 60px;

    a {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
      color:white;
      text-decoration: none;
    }

    .loader {
      width: 16px;
      height: 16px;
    }
  }
}