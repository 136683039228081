@use "../../../assets/scss/typography";

.container {
  cursor: pointer;

  .icon {
    width: 24px;
    height: 24px;
  }

  .text {
    flex-grow: 1;
    max-width: calc(100% - 2 * 24px - 16px);
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #292929;
    @include typography.body-large;
  }
}