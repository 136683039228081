@use '../../../assets/scss/typography';

.wrapper {
  position: sticky;
  top: 120px;
  box-sizing: border-box;
  min-width: 31%;
  width: 448px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 14px;
  background-color: transparent;

  .container {
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    border-radius: 16px;
    background-color: #FFFFFF;
    color: #292929;
    transition: all 0.3s;

    header {
      position: relative;
      display: flex;
      justify-content: space-between;

      .text {

        .h2Wrapper {
          display: flex;
          align-items: center;
          gap: 8px;

          h2 {
            @include typography.heading-small;
          }

          .locksmithButton {
            padding: 0;
            display: flex;

            .locksmithIcon {
              width: 32px;
              height: 32px;
            }

            .semitransparentLocksmithIcon {
              opacity: 0.5;
            }
          }
        }

        p {
          font-size: 12px;
          font-weight: 300;
          line-height: 16px;
        }
      }

      .buttons {
        display: flex;
        gap: 16px;

        .button {
          padding: 0;

          .trashIcon, .dotsIcon {
            display: block;
          }
        }
      }
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 16px;
      transition: all 0.3s;
    }

    .showMore {
      display: none;
    }

    .loader {
      height: 56px;
      width: 56px;
    }
  }

  .removeBtn {
    align-self: center;
    width: 414px;
    max-width: 100%;
    display: flex;
    gap: 8px;

    p {
      color: #E00000;
    }
  }
}

@media (max-width: 1000px) {
  .wrapper {
    position: static;
    width: 100%;

    .container {
      gap: 8px;

      .list {
        max-height: 0;
        overflow: hidden;
      }

      .openedList {
        max-height: 1000px;
        overflow: auto;
      }

      .showMore {
        display: block;
      }
    }

    .openedContainer {
      gap: 24px;
    }

    .removeBtn {
      width: 100%;
    }
  }
}

@media (max-width: 400px) {
  .wrapper {
    .container {
      header {
        .text {
          .h2Wrapper {
            gap: 4px;

            h2 {
              font-size: 18px;
            }

            .locksmithLoader {
              width: 16px;
              height: 16px;
            }

            .locksmithButton {
              .locksmithIcon {
                width: 24px;
                height: 24px;
              }
            }
          }
        }

        .buttons {
          gap: 8px;

          .trashIcon, .dotsIcon {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
  }
}