.container {
  padding: 16px 80px 0;
  display: grid;
  grid-template-columns: 23% auto 23%;
  grid-column-gap: 16px;
}

@media (max-width: 1440px) {
  .container {
    grid-template-columns: 30% auto;
  }
}

@media (max-width: 1000px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
    display: flex;
    flex-direction: column;
  }
}