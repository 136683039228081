@mixin heading-large {
  font-size: 40px;
  font-weight: 700;
  line-height: 48px;
}

@mixin heading-medium {
  font-size: 32px;
  font-weight: 700;
  line-height: 40px;
}

@mixin heading-small {
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
}

@mixin sub-heading {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

@mixin body-large {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

@mixin body-small {
  font-size: 14px;
  font-weight: 300;
  line-height: 24px;
}

@mixin tertriary {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
}

@mixin button-caps {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

@mixin overline {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.32em;
}

@mixin body-large-bold {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

@mixin body-small-bold {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}

@mixin tertriary-bold {
  font-size: 12px;
  font-weight: 600;
  line-height: 16px;
}

@mixin button {
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
}