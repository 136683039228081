@use "../../../../assets/scss/colors";
@use '../../../../assets/scss/typography';

.container {
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  background-color: #FFFFFF;
  cursor: pointer;

  .header {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .influencerInfo {
      display: flex;
      gap: 8px;
      overflow: hidden;

      .influencerText {
        flex-direction: column;
        gap: 0;
        overflow: hidden;

        h2 {
          font-size: 16px;
          font-weight: 500;
          line-height: 24px;
          color: #292929;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        h3 {
          font-size: 12px;
          font-weight: 400;
          line-height: 24px;
          color: #A3A3A3;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }

    .dotsButton {
      padding: 0;
      position: relative;
      display: flex;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    gap: 8px;

    .productImg {
     // height: 200px;
      aspect-ratio: 584/286;
      object-fit: contain;
      border-radius: 8px;
    }

    .productText {
      h2 {
        color: #292929;
        @include typography.sub-heading;
      }

      .reviews {
        display: flex;
        align-items: center;
        gap: 4px;

        p {
          font-size: 12px;
          font-weight: 300;
          line-height: 24px;
        }
      }
    }
  }
}