.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background-color: #EBEBEE;
  border: none;
  padding: 7px 10px;
  cursor: pointer;

  p {
    font-weight: 600;
    color: #212121;
    white-space: nowrap;
  }
}

@media (max-width: 768px) {
  .btn {
    p {
      display: none;
    }
  }
}