@use '../../assets/scss/colors';

.main {
  padding: 16px 32px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  border: none;
  white-space: nowrap;
  box-sizing: border-box;
  cursor: pointer;
}

.contained {
  background-color: colors.$primary;
  color: #FFFFFF;

  &.disabled {
    background-color: colors.$grey-grey !important;
    cursor: auto;
  }
}

.outlined {
  background-color: #FFFFFF;
  color: colors.$primary;
  border: 1px solid colors.$primary;

  &.disabled {
    border-color: colors.$grey-grey;
    color: colors.$grey-grey;
    cursor: auto;
  }
}

.text {
  background-color: #FFFFFF;
  color: colors.$primary;

  &.disabled {
    color: colors.$grey-grey;
    cursor: auto;
  }
}