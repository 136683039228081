@use '../../../assets/scss/typography';
@use '../../../assets/scss/colors';

.header {
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  h2 {
    @include typography.sub-heading;
  }

  .buttons {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .button {
      padding: 0;
      display: flex;
      align-items: center;
      gap: 4px;

      p {
        min-width: 24px;
        text-align: left;
        color: #292929;
        @include typography.tertriary;

        &.isLikedOrDisliked {
          color: colors.$primary;
        }
      }
    }
  }
}

@media (max-width: 360px) {
  .header {
    .buttons {
      p {
        display: none;
      }
    }
  }
}