@use '../../../assets/scss/colors';
@use '../../../assets/scss/typography';

.container {
  position: sticky;
  top: 120px;
  height: fit-content;
  min-width: 21%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #FFFFFF;
  border-radius: 16px;
  box-shadow: 0 8px 24px rgba(41, 41, 41, 0.02);

  .dotsBtn {
    position: absolute;
    top: 24px;
    right: 24px;
    padding: 0;
    background-color: transparent;

    .icon {
      display: block;
    }
  }

  .avatar {
    width: 96px;
    height: 96px;
  }

  h2 {
    margin-top: 16px;
    @include typography.heading-small;
    color: colors.$grey-black;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  h3 {
    @include typography.body-large;
    color: #A3A3A3;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .followButton {
    margin-top: 24px;
    padding-left: 16px;
    padding-right: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border-radius: 8px;

    .followIcon {
      width: 16px;
      height: 16px;
    }

    p {
      font-size: 14px;
      font-weight: 600;
      line-height: 16px;
    }

    .smallLoader {
      width: 12px;
      height: 12px;
    }
  }

  .followedBy {
    box-sizing: border-box;
    margin-top: 36px;
    padding: 8px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 16px;
    background-color: colors.$grey-light-grey;

    .avatar {
      width: 24px;
      height: 24px;
    }

    p {
      color: #292929;
      font-size: 12px;
      font-weight: 300;
      line-height: 16px;

      span {
        font-weight: 600;
      }
    }
  }

  .followingAndFollowers {
    position: relative;
    margin: 24px 0;
    padding: 24px 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 48px;
    border-top: 1px solid #E0E0E0;
    border-bottom: 1px solid #E0E0E0;
    transition: all .3s;

    &:after {
      content: "";
      position: absolute;
      width: 1px;
      height: calc(100% - 24px * 2);
      background-color: #E0E0E0;
    }

    div {
      display: flex;
      flex-direction: column;
      align-items: center;

      h2 {
        margin-top: 0;
        @include typography.sub-heading;
        color: colors.$grey-black;
      }
    }
  }

  .bio {
    display: flex;
    flex-direction: column;
    gap: 8px;
    color: #292929;
    transition: all .3s;

    h2 {
      margin-top: 0;
      @include typography.sub-heading;
    }

    p {
      font-size: 12px;
      font-weight: 300;
      line-height: 24px;
    }
  }

  .showMore {
    display: none;
  }

  .sandwichPopup {
    top: 50px;
    transform: translateY(0);
    height: fit-content;
  }
}

.largeLoader {
  margin-bottom: 20px;
  height: 56px;
  width: 56px;
}

@media (max-width: 1000px) {
  .container {
    position: relative;
    top: 0;
    margin-bottom: 16px;

    .followingAndFollowers {
      justify-content: space-around;
      margin: 0;
      padding: 0;
      max-height: 0;
      overflow: hidden;
      border-color: transparent;

      div {
        max-height: 0;
        overflow: hidden;
      }
    }

    .followedBy {
      margin: 0;
      padding: 0;
      max-height: 0;
      overflow: hidden;

      img {
        max-height: 0;
      }

      p {
        max-height: 0;
        overflow: hidden;
      }
    }

    .bio {
      max-height: 0;
      overflow: hidden;
    }

    .openedFollowedBy {
      margin-top: 36px;
      padding: 8px;
      max-height: 500px;
      overflow: auto;

      img {
        max-height: 24px;
      }

      p {
        max-height: 500px;
        overflow: auto;
      }
    }

    .openedFollowingAndFollowers {
      margin: 24px 0;
      padding: 24px 0;
      max-height: 500px;
      overflow: auto;
      border-color: #E0E0E0;

      div {
        max-height: 500px;
        overflow: auto;
      }
    }

    .openedBio {
      max-height: 500px;
      overflow: auto;
    }

    .showMore {
      display: block;
    }

    .dotsBtn {
      .sandwichPopup {
        right: 0;
      }
    }
  }
}