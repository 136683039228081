@use "../../assets/scss/typography";

.container {
  box-sizing: border-box;
  padding: 16px;
  width: 398px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  border-radius: 16px;
  background-color: #FFFFFF;
  color: #292929;

  .header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;

    h2 {
      color: #292929;
      @include typography.heading-small;
    }
  }

  .loader {
    width: 20px;
    height: 20px;
  }
}


@media (max-width: 700px) {
  .container {
    .header {
      .createBtn {
        padding-left: 16px;
        padding-right: 16px;
      }
    }
  }
}

@media (max-width: 560px) {
  .container {
    .header {
      .createBtn {
        padding-left: 8px;
        padding-right: 8px;
      }
    }
  }
}