@use '../../assets/scss/colors';
@use '../../assets/scss/typography';

.header {
  position: sticky;
  top: 0;
  width: 100%;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
  box-sizing: border-box;
  background-color: #FFFFFF;
  z-index: 1;
}

.container {
  position: relative;
  width: 100%;
  padding: 24px 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
}

.search {
  width: 577px;
  padding: 16px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  order: initial;
  background-color: colors.$grey-light-grey;

  input {
    width: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    font-size: 12px;
    font-weight: 300;
    line-height: 16px;
    text-overflow: ellipsis;

    &::placeholder {
      color: #A3A3A3;
    }

    &::-ms-clear {
      display: none;
      width: 0;
      height: 0;
    }

    &::-ms-reveal {
      display: none;
      width: 0;
      height: 0;
    }

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
      display: none;
    }
  }
}

.logoWrapper {
  width: 37%;
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    @include typography.sub-heading;
    cursor: pointer;
  }

  .backBtn {
    display: flex;
    padding: 0;
  }
}

.logo {
  width: 48px;
  height: 48px;
  border-radius: 16px;
  cursor: pointer;
  background-image: url("/assets/favicon-32x32.png");
  background-repeat: no-repeat, repeat;
  background-position: center;
}

.mobileSidebarWrapper {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  height: 100vh;
  width: fit-content;
  max-width: 100%;
  flex-direction: column;
  gap: 16px;
  background-color: colors.$grey-light-grey;
  transition: opacity .3s;
  box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;
  opacity: 0;
  pointer-events: none;
  touch-action: none;

  .closeBtn {
    padding: 0;
    margin-right: 16px;
    height: 104px;
    background-color: transparent;
    border: none;
    width: fit-content;
    align-self: flex-end;
    cursor: pointer;

    .closeBtnIcon {
      width: 24px;
      height: 24px;
    }
  }
}

.openedMobileSidebarWrapper {
  opacity: 1;
  pointer-events: auto;
  touch-action: auto;
}

.mobileSidebar {
  display: none;
  margin-left: 0;
  margin-right: 0;
  width: fit-content;
  max-width: 100%;
}

.mobileSearchButton {
  display: none;
  padding-right: 25px;
  padding-left: 25px;
  background-color: colors.$grey-light-grey;
}


@media (max-width: 1200px) {
  .mobileSidebarWrapper, .mobileSidebar {
    display: flex;
  }
}

@media (max-width: 1000px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
  .logoWrapper {
    width: fit-content;
  }
}

@media (max-width: 768px) {
  .button {
    span {
      display: none;
    }
  }
  .logoWrapper {
    p {
      display: none;
    }
  }
}

@media (max-width: 500px) {
  .container {
    flex-wrap: wrap;
    gap: 0;
  }
  .mobileSearchButton {
    display: flex;
  }
  .search {
    order: 5;
    max-width: 100%;
    overflow: hidden;
    padding-top: 0;
    padding-bottom: 0;
    height: 0;
    transition: all .3s;
  }
  .openedMobileSearch {
    display: flex;
    height: 56px;
    margin-top: 16px;
  }
}

@media (max-width: 360px) {
  .container {
    gap: 8px;
  }
  .mobileSearchButton {
    padding-left: 16px;
    padding-right: 16px;
  }
}
