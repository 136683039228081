$primary: #2C56E4;
$secondary: #F57F31;
//Shades of Grey
$grey-black: #081029;
$grey-dark-grey: #676B7A;
$grey-grey: #A9ACB8;
$grey-mid-grey: #E6E7EB;
$grey-light-grey: #FAFAFA;
//Alerts/Warnings
$alerts-red: #FF0000;
$alerts-yellow: #FFBF00;
$alerts-green: #43C900;