@use '../../assets/scss/colors';

.container {
  box-sizing: border-box;
  width: 648px;
  max-width: 100%;
  padding: 24px;
  border-radius: 16px;
  background-color: #FFFFFF;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  & > * {
    width: 100%;
    padding: 16px;
    border-radius: 8px;
    background-color: colors.$grey-light-grey;
    border: none;
    outline: none;
    font-size: 12px;
    font-weight: 300;
    line-height: 24px;

    &::placeholder {
      color: #A3A3A3;
    }
  }

  textarea {
    resize: none;
    height: calc(112px - 2 * 16px);
  }

  .url, .selectListsWrapper {
    flex: 1 0;
    display: flex;
    justify-content: space-between;
  }

  .selectListsWrapper, .dragAndDrop {
    color: #A3A3A3;
    cursor: pointer;
  }

  .fileInput {
    display: none
  }

  .selectListsWrapper {
    position: relative;
    max-width: calc(50% - 32px - 4px);

    .text {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: calc(100% - 24px);
    }

    .selectList {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      box-shadow: rgba(0, 0, 0, 0.35) 0 5px 15px;

      h2 {
        cursor: auto;
      }
    }
  }

  .dragAndDrop {
    position: relative;
    padding: 0;
    aspect-ratio: 600/224;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    border: 1px dashed #E0E0E0;

    p {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      opacity: 1;
    }

    .removeImageButton {
      padding: 0;
      display: flex;
      position: absolute;
      background-color: transparent;
      top: 10px;
      right: 10px;
    }
  }

  .dragAndDropDisabled {
    cursor: default;
  }

  .btn {
    background-color: colors.$primary;

    p {
      font-size: 14px;
      font-weight: 500;
    }

    .loader {
      width: 20px;
      height: 20px;
    }
  }

  .notAllowedFieldValue {
    outline: 1px solid red;
  }

  .notAllowedFileInputValue {
    border-color: red;
  }
}

@media (max-width: 500px) {
  .container {
    .url, .selectListsWrapper {
      flex: 0 1 auto;
      max-width: 100%;
    }
  }
}